import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/Users/uguryildirim/git/eteration/ui-components/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Props } from 'docz';
import Playground from 'components/Playground';
import Toast from "components/Toast";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "toast"
    }}>{`Toast`}</h1>

    <Playground __position={0} __code={'<Toast\n  color=\"danger\"\n  header=\"Eteration UI\"\n  body=\"This is a toast on a white background — check it out!\"\n  position=\"top-right\"\n/>'} __scope={{
      props,
      DefaultLayout,
      Props,
      Playground,
      Toast,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <Toast color="danger" header="Eteration UI" body="This is a toast on a white background — check it out!" position="top-right" mdxType="Toast" />
    </Playground>
    <Props of={Toast} mdxType="Props" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      